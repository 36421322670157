import React from 'react';
import { Col, Flex, Row, Skeleton } from 'antd';

export const ManageUsersModalContentSkeleton: React.FC = () => {
	return (
		<Flex vertical gap={24} style={{ overflow: 'hidden', marginTop: '24px' }}>
			<ManageUsersRowSkeleton />
			<Skeleton.Button shape="round" />
		</Flex>
	);
};

export const ManageUsersRowSkeleton: React.FC = () => {
	return (
		<Row align="middle" wrap={false} gutter={18}>
			<Col>
				<Skeleton.Avatar active shape="circle" />
			</Col>
			<Col flex="auto">
				<Flex vertical gap={4}>
					<Skeleton title={false} active loading paragraph={{ rows: 1, width: '50%' }} />
				</Flex>
			</Col>
		</Row>
	);
};

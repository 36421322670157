/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import { IconProps } from '@sharefiledev/icons';
import type {
	BaseButtonProps,
	ButtonType,
	SizeType,
} from '@sharefiledev/sharefile-appshell';
import type {
	ConditionalLinkConditions,
	EvaluatePost200Response,
	PermissionConfigurationV1,
	Preset,
} from '../generated/models';
import type { LocaleKeys } from '../locales/allLocaleKeys';
import { LocaleString } from '../localizedString';

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'permissions-pilet';
const modalNamespace = 'sfmodal';

export const permissionsPiletExtensionSlots = {
	shareButtonSlot: `${prefix}:${namespace}:${owner}:sharebutton` as const,
	avatarGroupSlot: `${prefix}:${namespace}:${owner}:avatargroup` as const,
	avatarGroupWithShareButtonSlot:
		`${prefix}:${namespace}:${owner}:avatargroup-with-sharebutton` as const,
	assignButtonSlot: `${prefix}:${namespace}:${owner}:assignbutton` as const,
};

export const permissionsPiletModals = {
	shareModal: `${prefix}:${modalNamespace}:${owner}:share` as const,
	linkBasedShareModal:
		`${prefix}:${modalNamespace}:${owner}:linkBasedShareModal` as const,
	manageUsersModal: `${prefix}:${modalNamespace}:${owner}:manageusers` as const,
};

export const permissionsPiletEvents = {
	shareSuccess: `${prefix}:${modalNamespace}:${owner}:share-success` as const,
	removeSuccess: `${prefix}:${modalNamespace}:${owner}:remove-success` as const,
	replaceSuccess: `${prefix}:${modalNamespace}:${owner}:replace-success` as const,
};

export interface PermissionsConfiguration extends PermissionConfigurationV1 {
	/** Used to detect when the permission configuration actually changes */
	hash?: string;
	permissionSourceGroups?: PermissionSourceGroup[];
}

export interface ShareSuccessReturnData {
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
	message?: string;
	selectedPermissions?: string[];
	relatedRidsShared?: string[];
	preset?: Preset;
	eventId?: string;
}

export interface RemoveSuccessReturnData {
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
}

export interface PermissionSourceGroup {
	label: string;
	description?: string;
	sourceLabels: string[];
}

export type Languages = 'en' | 'es' | 'de' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export type TranslatedStrings = Partial<Record<Languages, string>>;
export type Locale<T> = {
	key: T;
	value: TranslatedStrings;
};

interface CoreSlotExtensionParams {
	configuration: PermissionsConfiguration;
	shareEntity: string;
	showMessageField?: boolean;
	onShareSuccess?(shareSuccessData: ShareSuccessReturnData): Promise<void> | void;
	onRemoveSuccess?(removeSuccessData: RemoveSuccessReturnData): Promise<void> | void;
	allowedUserRole?: string;
	shareButtonText?: string;
	shareButtonTextInManageModal?: string;
	shareModalTitle?: string;
	labels?: Locale<LocaleKeys>[];
	eventId?: string;
	// Should be the name as per the PDS
	consumerPluginName?: string;
}

export interface PermissionsShareButtonSlotExtensionParams
	extends CoreSlotExtensionParams {
	/** Should be of the same RID leaf type */
	subjectRIDs: string[];
	disableCustomPermissions?: boolean;
	showShareIcon?: boolean;
	buttonType?: ButtonType;
	buttonSize?: SizeType;
	buttonProps?: Omit<BaseButtonProps, 'icon' | 'children'>;
	iconProps?: IconProps;
	disableMultiUserSelect?: boolean;
	/**
	 * Provides a pre-recorded response to the component. When passed, the component will *not*
	 * fetch data from the server and instead claim this data as a proper response.
	 */
	evaluateResponse?: EvaluatePost200Response;
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
	allowShareToUserGroups?: boolean;
}

export interface PermissionsLinkBasedShareModalSlotExtensionParams
	extends CoreSlotExtensionParams {
	/** Should be of the same RID leaf type */
	parentContainerRid: string;
	selectedResources: SelectedResource[];
	disableCustomPermissions?: boolean;
	showShareIcon?: boolean;
	buttonType?: ButtonType;
	buttonSize?: SizeType;
	buttonProps?: Omit<BaseButtonProps, 'icon' | 'children'>;
	iconProps?: IconProps;
	/**
	 * Provides a pre-recorded response to the component. When passed, the component will *not*
	 * fetch data from the server and instead claim this data as a proper response.
	 */
	evaluateResponse?: EvaluatePost200Response;
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
	allowShareToUserGroups?: boolean;
	hidePermissionDrawer?: boolean;
	conditionalLinkConditions?: ConditionalLinkConditions[];
	/**
	 * The expiration options to be displayed in the expiration dropdown. e.g. [1, 7, 30, 90, 365]. Number represents number of days.
	 * -1 represents never expire.
	 * If not provided, the default expiration options will be used.
	 */
	expirationOptions?: number[];
}

export interface SelectedResource {
	name: string;
	rid: string;
}

export interface PermissionsAssignButtonSlotExtensionParams
	extends CoreSlotExtensionParams {
	/** Should be of the same RID leaf type */
	subjectRIDs: string[];
	disableCustomPermissions?: boolean;
	buttonType?: ButtonType;
	buttonSize?: SizeType;
	buttonProps?: Omit<BaseButtonProps, 'icon' | 'children'>;
	iconProps?: IconProps;
	disableMultiUserSelect?: boolean;
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
	allowShareToUserGroups?: boolean;
}

export type ShareModalContentParams = CoreSlotExtensionParams &
	PermissionsShareButtonSlotExtensionParams;

export type LinkBasedShareModalContentParams = CoreSlotExtensionParams &
	PermissionsLinkBasedShareModalSlotExtensionParams;

export interface AvatarGroupSlotExtensionParams extends CoreSlotExtensionParams {
	subjectRID: string;
	resourceName?: string;
	hideShareButtonInModal?: boolean;
	removeUserConfirmMessage?: string;
	showInTour?: boolean;
	tourStep?: TourParams;
	solutionAnalyticsId?: string;
	showNameWhenSingleUser?: boolean;
	disableMultiUserSelect?: boolean;
	viewOnlyMode?: boolean;
	/**
	 * @deprecated Use principalRIDs instead.
	 */
	userIds?: string[];
	principalRIDs?: string[];
	allowShareToUserGroups?: boolean;
	/**
	 * Configure the extension slot to clear it's temporary caching
	 * upon receiving the event with a specified name.
	 *
	 * This is useful when the extension slot is used in a list of items
	 * with repetitive inputs or using virtualization/windowing rendering
	 * techniques.
	 *
	 * @example
	 * The parent list component would implement an unmount effect like the
	 * following:
	 *
	 * ```tsx
	 * React.useEffect(() => {
	 *   // inform all virtual mounted children that they will be unmounted
	 *   // permanently vs. temporarily
	 *   return () => piletApi.emit('urn:sfevent:my-pilet:list:unmount');
	 * }, [piletApi]);
	 * ```
	 */
	clearScrollCacheEventName?: string;
}

export type AvatarGroupWithShareButtonSlotExtensionParams = CoreSlotExtensionParams &
	AvatarGroupSlotExtensionParams &
	Omit<PermissionsShareButtonSlotExtensionParams, 'subjectRIDs'> & {
		tourSteps?: AvatarGroupWithShareButtonTourSteps;
	};

type AvatarGroupWithShareButtonTourSteps = {
	avatarGroupTourStep?: TourParams;
	shareButtonTourStep?: TourParams;
};

export type TourParams = {
	title: LocaleString;
	description: LocaleString;
	weight: number;
};

export type ManageUsersModalContentParams = CoreSlotExtensionParams &
	AvatarGroupSlotExtensionParams &
	Omit<PermissionsShareButtonSlotExtensionParams, 'subjectRIDs'>;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[permissionsPiletExtensionSlots.shareButtonSlot]: PermissionsShareButtonSlotExtensionParams;
		[permissionsPiletExtensionSlots.avatarGroupSlot]: AvatarGroupSlotExtensionParams;
		[permissionsPiletExtensionSlots.avatarGroupWithShareButtonSlot]: AvatarGroupWithShareButtonSlotExtensionParams;
		[permissionsPiletExtensionSlots.assignButtonSlot]: PermissionsAssignButtonSlotExtensionParams;
	}

	interface PiralCustomOverlayOptionsMap {
		[permissionsPiletModals.shareModal]: ShareModalContentParams;
		[permissionsPiletModals.linkBasedShareModal]: LinkBasedShareModalContentParams;
		[permissionsPiletModals.manageUsersModal]: ManageUsersModalContentParams;
	}

	interface PiralCustomOverlayDataMap {
		[permissionsPiletModals.shareModal]: {
			value: boolean;
		};
		[permissionsPiletModals.linkBasedShareModal]: {
			value: boolean;
		};
		[permissionsPiletModals.manageUsersModal]: {
			value: boolean;
		};
	}

	interface PiralCustomEventMap {
		[permissionsPiletEvents.shareSuccess]: {
			RIDs: string[];
			shareSuccessReturnData: ShareSuccessReturnData;
		};
		[permissionsPiletEvents.removeSuccess]: {
			RIDs: string[];
			removeSuccessReturnData: RemoveSuccessReturnData;
		};
		[permissionsPiletEvents.replaceSuccess]: {
			RIDs: string[];
			shareSuccessReturnData: ShareSuccessReturnData;
		};
	}
}

export interface PrivateUserGroupData {
	groupRID: string;
	groupName?: string;
	loading?: boolean;
	memberCount?: number;
	icon?: React.ReactNode;
	onClickMemberCount?(groupRID: string): void;
}

export interface PrivateGroupDataProviderExtensionSlotParameters {
	groupRID: string;
	groupName: string;
	Component: React.FC<PrivateUserGroupData>;
}

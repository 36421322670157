import React from 'react';
import { Flex, Skeleton } from 'antd';

export const ShareModalContentSkeleton: React.FC = () => {
	return (
		<Flex vertical gap={24} style={{ overflow: 'hidden', marginTop: '24px' }}>
			<Skeleton.Input active block />
			<Skeleton.Input active block />
			<Flex gap={10}>
				<Skeleton.Button active shape="round" />
				<Skeleton.Button active shape="round" />
			</Flex>
		</Flex>
	);
};

import React from 'react';
import { CodeOutlined } from '@ant-design/icons';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import {
	AsyncAssignButton,
	AsyncAvatarGroup,
	AsyncAvatarGroupWithShareButton,
	AsyncShareButton,
	LazyDemoPage,
	LazyGrantPage,
	LazyLinksPage,
} from './ExtensionSlots';
import { setLogger } from './logger';
import {
	AsyncLinkBasedShareModal,
	AsyncManageUsersModalContent,
	AsyncShareModalContent,
} from './Modals';
import {
	getDefaultLinkBasedShareModalOptions,
	getDefaultManageUsersModalOptions,
	getDefaultShareModalOptions,
} from './Modals/getDefaultModalOptions';
import { permissionsPiletExtensionSlots } from './sdk';
import { permissionsPiletModals } from './sdk/extensionTypes';
import { GRANT_ACCESS_PATH, RESOLVE_ACCESS_PATH } from './utils/constants';

export function setup(piletApi: PiletApi) {
	setLogger(piletApi.sf.getLogger());

	piletApi.registerExtension(
		permissionsPiletExtensionSlots.shareButtonSlot,
		({ params }) => <AsyncShareButton piletApi={piletApi} {...params} />
	);

	piletApi.registerExtension(
		permissionsPiletExtensionSlots.avatarGroupSlot,
		AsyncAvatarGroup
	);

	piletApi.registerExtension(
		permissionsPiletExtensionSlots.avatarGroupWithShareButtonSlot,
		({ params }) => <AsyncAvatarGroupWithShareButton piletApi={piletApi} {...params} />
	);
	piletApi.registerExtension(
		permissionsPiletExtensionSlots.assignButtonSlot,
		({ params }) => <AsyncAssignButton piletApi={piletApi} {...params} />
	);

	piletApi.registerModal(
		permissionsPiletModals.shareModal,
		({ options, cancelInteraction, completeInteraction }) => (
			<AsyncShareModalContent
				piletApi={piletApi}
				completeInteraction={completeInteraction}
				cancelInteraction={cancelInteraction}
				{...options!}
			/>
		),
		getDefaultShareModalOptions()
	);

	piletApi.registerModal(
		permissionsPiletModals.linkBasedShareModal,
		({ options, cancelInteraction, completeInteraction }) => (
			<AsyncLinkBasedShareModal
				piletApi={piletApi}
				completeInteraction={completeInteraction}
				cancelInteraction={cancelInteraction}
				{...options!}
			/>
		),
		getDefaultLinkBasedShareModalOptions()
	);

	piletApi.registerModal(
		permissionsPiletModals.manageUsersModal,
		({ options, cancelInteraction, completeInteraction }) => (
			<AsyncManageUsersModalContent
				piletApi={piletApi}
				completeInteraction={completeInteraction}
				cancelInteraction={cancelInteraction}
				{...options!}
			/>
		),
		getDefaultManageUsersModalOptions()
	);

	piletApi.registerPage(
		`${RESOLVE_ACCESS_PATH}/:lookupkey`,
		() => <LazyLinksPage piletApi={piletApi} />,
		{ pageType: 'fullPage' }
	);

	piletApi.registerPage(
		`${GRANT_ACCESS_PATH}/:linkKey`,
		() => <LazyGrantPage piletApi={piletApi} />,
		{ pageType: 'fullPage' }
	);

	registerDevPage(piletApi);
}

function registerDevPage(piletApi: PiletApi) {
	if (process.env.NODE_ENV === 'development') {
		piletApi.sf.registerLeftNavComponent({
			href: '/permissions-dev',
			title: () => 'Permissions Dev',
			icon: () => <CodeOutlined style={{ fontSize: '24px' }} />,
			weight: 50,
		});
		if (typeof piletApi.meta.config.apiOrigin === 'string') {
			const { configureHttp } = require('./PermissionHttpConfiguration');
			const { Configuration } = require('./generated/runtime');
			configureHttp((configuration: any) => {
				configuration.config = new Configuration({
					basePath: `${piletApi.meta.config.apiOrigin}/io/permissions/v1`,
				});
			});
		}
	}

	piletApi.registerPage('/permissions-dev', () => <LazyDemoPage piletApi={piletApi} />);
}

import React from 'react';
import type { OverlayModalOptions } from '@sharefiledev/sharefile-appshell';
import { permissionsPiletModals } from '../sdk/extensionTypes';
import { TranslatedString } from '../utils/TranslatedString';

export function getDefaultShareModalOptions(): Partial<
	OverlayModalOptions<typeof permissionsPiletModals.shareModal>
> {
	return {
		modalProps: {
			title: <TranslatedString localizationKey="shareModal.defaultTitle" />,
			centered: true,
			width: 600,
			styles: { body: { maxHeight: '100vh', overflowY: 'auto' } },
			footer: null,
		},
	};
}

export function getDefaultLinkBasedShareModalOptions(): Partial<
	OverlayModalOptions<typeof permissionsPiletModals.linkBasedShareModal>
> {
	return {
		modalProps: {
			title: <TranslatedString localizationKey="shareModal.defaultTitle" />,
			centered: true,
			width: 600,
			styles: { body: { maxHeight: '100vh', overflowY: 'auto' } },
			footer: null,
		},
	};
}

export function getDefaultManageUsersModalOptions(): Partial<
	OverlayModalOptions<typeof permissionsPiletModals.manageUsersModal>
> {
	return {
		modalProps: {
			title: <TranslatedString localizationKey="manageUsers.title" />,
			centered: true,
			width: 600,
			styles: { body: { maxHeight: '50vh', overflowY: 'auto' } },
			footer: null,
		},
	};
}

import React from 'react';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import type { CoreExtensionSlotParams } from '../ExtensionSlots/types';
import {
	LinkBasedShareModalContentParams,
	ManageUsersModalContentParams,
	permissionsPiletModals,
	ShareModalContentParams,
} from '../sdk/extensionTypes';
import { ManageUsersModalContentSkeleton } from './ManageUsersModalContentSkeleton';
import { ShareModalContentSkeleton } from './ShareModalContentSkeleton';

export type ManageUsersModalContentProps =
	CoreExtensionSlotParams<ManageUsersModalContentParams> &
		Pick<
			OverlayModalComponentProps<typeof permissionsPiletModals.manageUsersModal>,
			'completeInteraction' | 'cancelInteraction'
		>;

const LazyManageUsersModalContent = React.lazy(() => import('./ManageUsersModalContent'));

export const AsyncManageUsersModalContent: React.FC<
	ManageUsersModalContentProps
> = props => (
	<React.Suspense fallback={<ManageUsersModalContentSkeleton />}>
		<LazyManageUsersModalContent {...props} />
	</React.Suspense>
);

export type ShareModalContentProps = CoreExtensionSlotParams<ShareModalContentParams> &
	Pick<
		OverlayModalComponentProps<typeof permissionsPiletModals.shareModal>,
		'completeInteraction' | 'cancelInteraction'
	>;

export type LinkBasedShareModalContentProps =
	CoreExtensionSlotParams<LinkBasedShareModalContentParams> &
		Pick<
			OverlayModalComponentProps<typeof permissionsPiletModals.linkBasedShareModal>,
			'completeInteraction' | 'cancelInteraction'
		>;

const LazyShareModalContent = React.lazy(() => import('./ShareModalContent'));

export const AsyncShareModalContent: React.FC<ShareModalContentProps> = props => (
	<React.Suspense fallback={<ShareModalContentSkeleton />}>
		<LazyShareModalContent {...props} />
	</React.Suspense>
);

const LazyLinkBasedShareModal = React.lazy(() => import('./LinkBasedShareModalContent'));

export const AsyncLinkBasedShareModal: React.FC<
	LinkBasedShareModalContentProps
> = props => (
	<React.Suspense fallback={<ShareModalContentSkeleton />}>
		<LazyLinkBasedShareModal {...props} />
	</React.Suspense>
);

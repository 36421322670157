import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { Skeleton, Space } from 'antd';
import {
	AvatarGroupSlotExtensionParams,
	AvatarGroupWithShareButtonSlotExtensionParams,
	PermissionsAssignButtonSlotExtensionParams,
	PermissionsShareButtonSlotExtensionParams,
} from '../sdk/extensionTypes';
import { CoreExtensionSlotParams } from './types';

const LazyShareButton = React.lazy(() => import('./ShareButton'));
export const AsyncShareButton: React.FC<
	CoreExtensionSlotParams<PermissionsShareButtonSlotExtensionParams>
> = props => (
	<React.Suspense fallback={<Skeleton.Button shape="round" />}>
		<LazyShareButton {...props} />
	</React.Suspense>
);

const LazyAvatarGroup = React.lazy(() => import('./AvatarGroup'));
export const AsyncAvatarGroup: React.FC<
	ExtensionComponentProps<AvatarGroupSlotExtensionParams>
> = props => (
	<React.Suspense fallback={<Skeleton.Avatar shape="circle" />}>
		<LazyAvatarGroup {...props} />
	</React.Suspense>
);

const LazyAvatarGroupWithShareButton = React.lazy(
	() => import('./AvatarGroupWithShareButton')
);
export const AsyncAvatarGroupWithShareButton: React.FC<
	CoreExtensionSlotParams<AvatarGroupWithShareButtonSlotExtensionParams>
> = props => (
	<React.Suspense
		fallback={
			<Space size="small">
				<Skeleton.Avatar shape="circle" />
				<Skeleton.Button shape="round" />
			</Space>
		}
	>
		<LazyAvatarGroupWithShareButton {...props} />
	</React.Suspense>
);

const LazyAssignButton = React.lazy(() => import('./AssignButton'));
export const AsyncAssignButton: React.FC<
	CoreExtensionSlotParams<PermissionsAssignButtonSlotExtensionParams>
> = props => (
	<React.Suspense fallback={<Skeleton.Button shape="round" />}>
		<LazyAssignButton {...props} />
	</React.Suspense>
);
/**
 * Does not need to use React suspense since this is not intended to be user facing
 */
export const LazyDemoPage = React.lazy(() => import('./DemoPage'));

export const LazyLinksPage = React.lazy(() => import('./LinksPage'));

export const LazyGrantPage = React.lazy(() => import('./GrantPage'));

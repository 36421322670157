import React from 'react';
import { Spin } from 'antd';
import { LocalizationLoader, t } from './translate';

interface Props {
	localizationKey: string;
}

export const TranslatedString: React.FC<Props> = ({ localizationKey }) => {
	return (
		<LocalizationLoader loadingElement={<Spin />}>
			{() => <>{t(localizationKey)}</>}
		</LocalizationLoader>
	);
};
